import React, { useEffect } from 'react'
import { MdClose } from 'react-icons/md'

const Modal = (props) => {

    useEffect(() => {
        const html = document.querySelector("html")
        html.style.overflowY = props.visible ? "hidden" : "visible"
    }, [props.visible])

    return props.visible ? (
        <div className="modal-container">
            <div className="modal-box">
                {props.children}
                <span className="close-button" onClick={() => props?.onClose?.()}>
                    <MdClose />
                </span>
            </div>
        </div>
    ) : null
}

export default Modal
