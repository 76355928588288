import React from "react";
import Img from "gatsby-image"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

// import { FiTwitter, FiInstagram, Fi, FiFacebook, FiMail } from "react-icons/fi";

export default ({ slides }) => {
    return (
        <div className="carousel">
            <Slide easing="ease" duration={4000}
                indicators={() => (<div className="indicator"></div>)}
            >
                {
                    slides.map(({ node }, ndx) => {
                        return (
                            <div className="each-slide" key={ndx}>
                                <picture className="slideshow-image">
                                    {
                                        node.frontmatter.cover_image &&
                                        <Img
                                            style={{ height: "100%" }}
                                            fluid={node.frontmatter.cover_image.childImageSharp.fluid} />
                                    }
                                </picture>
                                <span className="details highlight">
                                    <h3 className="blog-title">
                                        {node.frontmatter.title}
                                    </h3>
                                    <small>{node.frontmatter.date} | {node.frontmatter.cat}</small>
                                    <section
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                            __html: node.frontmatter.description || node.excerpt,
                                        }}
                                    />

                                </span>
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
    )
}