// Gatsby supports TypeScript natively!
import React, { useState, useEffect } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogList from "../components/bloglist"
import Slideshow from "../components/slideshow"
import { globalHistory } from "@reach/router"
import Modal from "../components/modal"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
          cat: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const [posts, setPosts] = useState(data.allMarkdownRemark.edges)
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const expiry = window.localStorage.getItem("display_popup")
    const ttl = 1800
    if (expiry) {
      if (Date.now() > parseInt(expiry)) {
        setModalVisible(true);
        window.localStorage.setItem("display_popup", `${Date.now() + 1000 * ttl}`)
      }
    } else {
      setModalVisible(true);
      window.localStorage.setItem("display_popup", `${Date.now() + 1000 * ttl}`)
    }
  })

  return (
    <Layout titleColor={null} location={location} title={siteTitle} previous={null} next={null} blogList>
      <SEO title="All posts" />
      <Slideshow slides={posts.slice(0, 6)} />
      <BlogList posts={posts} />
      <Modal visible={modalVisible} onClose={() => setModalVisible(false)} >
        <div className="subscribe-content">
          <h2><strong>Subscribe</strong></h2>
          <h4><small>Stay up to date with our posts!</small></h4>
        </div>
        <div className="subscribe-input">
          <form
            className="email"
            name="subscribe"
            method="POST"
            netlify
            onSubmit={() => {
              setModalVisible(false)
            }}
            data-netlify="true"
            netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="subscribe" />
            <input type="text" name="email" placeholder="Email Address" />
            <input type="submit" value="Subscribe" />
          </form>
        </div>
      </Modal>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            cat
            cover_image {
              childImageSharp {
                fluid(maxWidth: 2560) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width:600) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
